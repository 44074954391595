import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

export default {
    props: {
        data: {
            type: Array,
            default: () => []
        },
        isMock: {
            type: Boolean,
            default: true
        },
        height: {
            type: Number,
            default: 500
        },
        percentLineValueField: {
            type: String
        },
        percentLineText: {
            type: String
        },
        showPercent: {
            type: Boolean
        },
        valueText: {
            type: String
        }
    },
    data() {
        return {
            chart: null
        }
    },
    watch: {
        data: {
            handler(newVal, oldVal) {
                this.dispose();
                this.renderChart();
            }
        }
    },
    methods: {
        getData() {
            if (this.isMock) {
                return this.mockup();
            } else {
                return this.data;
            }
        },
        dispose() {
            if (this.chart) {
                this.chart.dispose();
            }
        },
        renderChart() {
            this.createChart();
            this.createPercentLine();
        },
        createPercentLine() {
            if (this.percentLineValueField) {
                var paretoValueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
                paretoValueAxis.renderer.opposite = true;
                paretoValueAxis.min = 0;
                paretoValueAxis.max = 100;
                paretoValueAxis.strictMinMax = true;
                paretoValueAxis.renderer.grid.template.disabled = true;
                paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
                paretoValueAxis.numberFormatter.numberFormat = "#'%'";
                paretoValueAxis.cursorTooltipEnabled = false;

                var paretoSeries = this.chart.series.push(new am4charts.LineSeries());
                paretoSeries.dataFields.valueY = this.percentLineValueField;
                paretoSeries.name = this.percentLineText;
                paretoSeries.dataFields.categoryX = this.categoryField;
                paretoSeries.yAxis = paretoValueAxis;
                paretoSeries.tooltipText = this.percentLineText + ": {valueY.formatNumber('#.0')}%[/]";
                paretoSeries.bullets.push(new am4charts.CircleBullet());
                paretoSeries.strokeWidth = 2;
                paretoSeries.stroke = new am4core.InterfaceColorSet().getFor(
                    "alternativeBackground"
                );
                paretoSeries.strokeOpacity = 0.5;
            }
        }
    },
    mounted() {
        this.renderChart();
    },
    beforeDestroy() {
        this.dispose();
    }
}