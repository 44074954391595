
<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
import BaseChart from "./base-chart";

import amchartMixins from "./../../_mixins/amchart-mixins";

export default {
  name: "pie-chart-with-legend",
  mixins: [amchartMixins],
  extends: BaseChart,
  props: {
    categoryField: {
      type: String,
      default: "country"
    },
    valueField: {
      type: String,
      default: "litres"
    }
  },
  data() {
    return {
      url: "https://www.amcharts.com/demos/3d-pie-chart/"
    };
  },
  methods: {
    mockup() {
      let data = [
        {
          country: "Lithuania",
          litres: 501.9
        },
        {
          country: "Germany",
          litres: 165.8
        },
        {
          country: "Australia",
          litres: 139.9
        },
        {
          country: "Austria",
          litres: 128.3
        },
        {
          country: "UK",
          litres: 99
        },
        {
          country: "Belgium",
          litres: 60
        }
      ];
      return data;
    },
    createChart() {
      let chart = am4core.create(this.$refs.chartdiv, am4charts.PieChart);
      this.chart = chart;

      // Add data
      chart.data = this.getData();

      // Add and configure Series
      var pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = this.valueField;
      pieSeries.dataFields.category = this.categoryField;

      // Add a legend
      chart.legend = new am4charts.Legend();
    }
  }
};
</script>

