<template>
  <div>
    <show-model :text="data"></show-model>
    <div
      class="chartdiv"
      ref="chartdiv"
      :style="{height: height+'px'}"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
};
</script>

<style scoped>
.chartdiv {
  width: 100%;
  letter-spacing: 0;
}
</style>

<style>
/* hidden logo */
.chartdiv g[aria-labelledby$="-title"] {
  display: none;
}
</style>

